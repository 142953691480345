<template>
	<v-row class="pui-form-layout">
		<v-col cols="12">
			<v-row dense>
				<v-col cols="2">
					<pui-select
						:id="`portid-${modelName}`"
						:attach="`portid-${modelName}`"
						:label="$t('form.berth.portid')"
						toplabel
						clearable
						:disabled="formDisabled"
						v-model="parentObject"
						modelName="port"
						:modelFormMapping="{ id: 'portid' }"
						:itemsToSelect="itemsToSelectPort"
						itemValue="id"
						itemText="portname"
						required
						:fixedFilter="filterByPortAuthorityAndPort"
						reactive
					></pui-select>
				</v-col>
				<v-col cols="3">
					<pui-select
						:id="`dockid-${modelName}`"
						:attach="`dockid-${modelName}`"
						:label="$t('form.berth.dockname')"
						:disabled="formDisabled"
						toplabel
						clearable
						modelName="dock"
						v-model="parentObject"
						reactive
						:itemsToSelect="[{ id: parentObject.dockid }]"
						:modelFormMapping="{ id: 'dockid' }"
						itemValue="id"
						itemText="description"
						:fixedFilter="filterByBerthPort"
						:order="{ description: 'asc' }"
					></pui-select>
				</v-col>
				<v-col cols="5">
					<pui-text-field
						:id="`name-${modelName}`"
						v-model="parentObject.name"
						:label="$t('form.berth.name')"
						:disabled="formDisabled"
						required
						toplabel
						maxlength="200"
					></pui-text-field>
				</v-col>
				<v-col cols="2">
					<pui-text-field
						:id="`berthcode-${modelName}`"
						v-model="parentObject.berthcode"
						:label="$t('form.berth.berthcode')"
						:disabled="formDisabled"
						required
						toplabel
						maxlength="200"
					></pui-text-field>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="3">
					<pui-radio-group
						:id="`radioSeasideType-${modelName}`"
						:label="$t('form.berth.seaside')"
						v-model="parentObject.seaside"
						:radios="viewSeasideRange"
						:disabled="formDisabled"
						required
					></pui-radio-group>
				</v-col>
				<v-col cols="3">
					<pui-select
						:id="`initbol-${modelName}`"
						v-model="parentObject"
						:label="$t('form.berth.initbol')"
						:disabled="formDisabled"
						modelName="bollard"
						toplabel
						:modelFormMapping="{ id: 'initbol' }"
						:itemsToSelect="[{ id: this.parentObject.initbol }]"
						itemValue="id"
						itemText="bolcode"
						:fixedFilter="filterByBerth"
						:order="{ orderby: 'asc' }"
					></pui-select>
				</v-col>
				<v-col cols="3">
					<pui-select
						:id="`endbol-${modelName}`"
						v-model="parentObject"
						:label="$t('form.berth.endbol')"
						:disabled="formDisabled"
						modelName="bollard"
						toplabel
						:modelFormMapping="{ id: 'endbol' }"
						:itemsToSelect="[{ id: this.parentObject.endbol }]"
						itemValue="id"
						itemText="bolcode"
						:fixedFilter="filterByBerth"
						:order="{ orderby: 'asc' }"
					></pui-select>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="3">
					<pui-number-field
						:id="`draught-${modelName}`"
						v-model="parentObject.draught"
						:label="$t('form.berth.draught')"
						:disabled="formDisabled"
						toplabel
					></pui-number-field>
				</v-col>
				<v-col cols="3">
					<pui-select
						:id="`activitytypereq-${modelName}`"
						:attach="`activitytypereq-${modelName}`"
						:label="$t('form.berth.activitytype')"
						toplabel
						clearable
						:disabled="formDisabled"
						v-model="parentObject"
						modelName="activitytype"
						:itemsToSelect="[{ code: this.parentObject.activitytype }]"
						:modelFormMapping="{ code: 'activitytype' }"
						itemValue="code"
						itemText="name"
						:order="{ name: 'asc' }"
						reactive
					></pui-select>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'berth-form-main-tab',

	data() {
		return {
			bethType: 1,
			berth: null,
			viewSeasideRange: [
				{
					label: this.$t('form.berth.seasideL'),
					id: 'L'
				},
				{
					label: this.$t('form.berth.seasideR'),
					id: 'R'
				}
			]
		};
	},
	props: {
		modelName: {
			type: String,
			required: true
		},
		parentObject: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		afterGetData() {}
	},
	computed: {
		itemsToSelectPort() {
			return [{ id: this.parentObject.portid }];
		},
		filterByPortAuthorityAndPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') },
					{ field: 'id', op: 'in', data: this.$store.getters.getPortsIds }
				]
			};
		},
		filterByBerth() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berthid', op: 'eq', data: this.parentObject.id }]
			};
		},
		filterByBerthPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portid', op: 'eq', data: this.parentObject.portid }]
			};
		}
	},
	watch: {
		'parentObject.name': {
			handler: function (newValue) {
				if (typeof newValue === 'undefined' || newValue == null) {
					this.parentObject.type = null;
				} else {
					this.parentObject.type = this.bethType;
				}
			}
		}
	}
};
</script>
