<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="filterByUserPortsAndAuthority"
			:actions="actions"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
			:externalFilter="filterByLocationFromAndLocationTo"
			:actions="actions"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import LocationsequenceheaderActions from './LocationsequenceheaderActions.js';

export default {
	name: 'locationsequenceheader-grid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'locationsequenceheader',
			actions: LocationsequenceheaderActions.gridactions,
			modelColumnDefs: {
				pilotage: {
					render: (data, type, row, meta) => {
						if (!data) {
							return this.$t('common.no');
						}
						return this.$t('common.yes');
					}
				}
			}
		};
	},
	computed: {
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portid', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') }
				]
			};
		},
		filterByLocationFromAndLocationTo() {
			return {
				groups: [],
				groupOp: 'or',
				rules: [
					{ field: 'locationto', op: 'eq', data: this.parentModel.id },
					{ field: 'locationfrom', op: 'eq', data: this.parentModel.id }
				]
			};
		}
	}
};
</script>
